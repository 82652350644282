.divider
{
	position: relative;
	height: 1px;
	margin: 0, 4vh;
}

.div-transparent:before
{
	content: "";
	position: absolute;
	top: 0;
	left: 5%;
	right: 5%;
	width: 90%;
	height: 1px;
	background-image: linear-gradient(to right, transparent, rgb(35, 34, 37), transparent);
}

