.footer {
    display: flex;
    justify-content: space-between;
    height: auto;
    min-height: 8vh;
    width: 100vw;
    padding: 1vh 7vw;
    text-align: center;
}

.media-icon {
    color: rgb(35, 34, 37, 0.6);
    font-size: 2em;
    margin-right: 10px;
    cursor: pointer;
}