/* teksty:: font-family: 'Marcellus', serif;
menu:: font-family: 'Cinzel', serif; */
/* podpisy pod obrazami: Gotu */

:root {
  --fontColor:rgb(35, 34, 37);
  /* --secondColor: ; */
}

html {
    box-sizing: border-box;
    font-family: 'Marcellus', serif;
    color: rgb(35, 34, 37);
    /* font-size: 16px; */
}
  
*, *:before, *:after {
    box-sizing: inherit;
  }
  
body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
    overflow-x: hidden;
    /* ??? */
  }
  
ol, ul {
    list-style: none;
  }
  
img {
    max-width: 100%;
    height: auto;
  }

.container { 
    font-family: 'Marcellus', serif;
    font-size: 1.1rem;
    line-height: 1.8rem;
    color: var(--fontColor);
    min-height: 72vh;
    width: 100vw;
    padding: 0vh 5vw;
  }

  .container--gallery {
    padding: 0vh 0vw;
  }

.title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
    margin: 2rem;
    letter-spacing: 0.5rem;
    padding: 0.5rem;
}


.small-line {
    width: 6rem;
}

.gallery-intro{
  height: 14rem;
  width: 19rem;
  background-color: rgb(145, 177, 175);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: cadetblue;
  opacity: 1;
  transition: opacity 1s;
  position: relative;
  margin: 15px auto;
  
} 

.gallery-intro:hover {
  opacity: 0.7;
}

.overlay-title{
  position: absolute;
  bottom: 0;
  background: rgb(35, 34, 37, 0.5);
  width: 100%;
  color: white;
  font-size: 1.2rem;
  padding: 0.3rem;
  text-align: center;
  text-transform: uppercase;
}

.intro-one {
  background-image: url(../src/img/covers/pokoj_podroznika.jpg);
}

.intro-two {
  background-image: url(../src/img/covers/niewidzialne_miasta.jpg);
}

.intro-three {
  background-image: url(../src/img/covers/katedra.jpg);
}

.intro-four {
  background-image: url(../src/img/covers/teatr_swiata.jpg);
}

.intro-five {
  background-image: url(../src/img/covers/mitologia_przedmiescia.jpg);
}

.intro-six {
  background-image: url(../src/img/covers/nowe_obrazy.jpg);
}

/* .gallery-area {
  display: flex;
  /* flex-wrap: wrap; */
  /* flex-direction: row; */
  /* justify-content: start; }*/

/* 
.gallery-area {
  width: 80vw;
  max-height: 200vh;
  max-width: 95vw;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
  padding: 2.8rem 6rem;
} */


#lightbox {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  display: none;
  }
  
  #lightbox.active {
  display:flex;
  justify-content: center;
  align-items: center;
  }
  
  
.container--blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4em;
}

.post {
 width: 65%;
 height: auto;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.post__header {
  width: 100%;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  margin: 1em;
  margin-top: 5em;
  padding-left: 1em;
  padding-right: 1em;
}

/* .post__date {
  
} */

.post__title {
  font-size: 1.1em;
}

.post__text {
  /* border: 1px solid rgb(172, 164, 164); */
  padding: 1em;
  text-align: justify;
  text-indent: 20px;
  /* border-radius: 5px; */
}

.post__photo{
  height: 40vh;
  width: auto;
  float: left;
  margin-top: 0.5rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.blog__photo{
  height: 45vh;
  width: auto;
  float: left;
  margin-top: 0.5rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
}


/* .bio__text {
  font-family: 'Marcellus', serif;
  font-size: 1.1rem;
  line-height: 1.8rem;
  color: var(--fontColor);
} */

/* .bio__para{
  margin-bottom: 1rem;
  text-indent: 20px;
  text-align: justify;
} */
/* 
.bio__list-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 3.5rem;
} */

.news {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  margin: 1em;
  margin-top: 5em;
  padding-left: 1em;
  padding-right: 1em;

}

.news__img {
  width: 250px;
  height: auto;
  background-color: rgb(126, 159, 197);
}

.news__text {
  padding: 1em;
}

.container--contact {
  width: 60%;
  min-height: 72vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .construction {
margin-left: 75px;
} */


.modal {
  position: fixed;
  /* max-width: 700px; */
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(253, 252, 252, 0.6);
  padding: 0 5px 5px 5px;
  border-radius: 3px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  display: none;
}

.modal__details {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px 12px 10px;
  background-color: rgba(70, 65, 65, 9);
  margin-bottom: 1em;
}

.modal__title {
  display: flex;
  font-size: 1.3em;
  font-weight: 400;
  color:white;
  margin-left: 0.5em;
}

.modal__img-box {
  display: flex;
  height: 85%;
  justify-content: space-evenly;
}

.modal__img {
  height: 100%;
  width: auto;
}

.modal__slide {
  position: absolute;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
  width: 60px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.modal__slide--prev {
  left: 0;
}

.modal__slide--next {
  right: 0;
}

.modal__slide-icon {
  font-size: 40px;
  cursor: pointer;
}

.modal__close-icon {
  color: white;
  cursor: pointer;
  font-size: 1.5em;
  margin-right: 0.5em;
}


.painting-details {
  background-color: white;
  font-size: 1.3em;
  font-weight: 400;
  opacity: 1;
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 50px;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Gotu', serif;
}

button {
  color: white;
  background-color: white;
  border: none;
  line-height: 100%;
  font-size: 1.5rem;
  width: auto;
  /* margin: 10px; */
  opacity: 0.7;
}

/* .image-card {
  width: 30vw;
  height: 30vw;
  object-fit: cover;
} */

#lightbox-img {
  max-height: 80vh;
  max-width: 66vw;
  object-fit: cover;
  margin: auto;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255);
  /* background-color: rgba(22, 21, 21, 0.9); */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
