.divider-mini
{
	position: relative;
    height: 1px;
	margin-top: 4em;  
	margin-bottom: 1em;  
}

.div-transparent:before
{
	content: "";
	position: absolute;
	top: 0;
	left: 15%;
	right: 15%;
	width: 70%;
	height: 1px;
	background-image: linear-gradient(to right, transparent, rgb(35, 34, 37), transparent);
}

